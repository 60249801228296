.Comments {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}

@media(min-width: 40rem) {
    .Comments {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
    }
}