.OrderSummary {
    padding: 10px 15px;
    max-width: 40rem;
    margin: auto;
    background-color: var(--foreground-color);
}

.OrderSummaryItems {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.OrderSummaryItem {
    width: 50%;
    text-align: left;
    margin-bottom: 10px;
    padding: 8px 0;
}
.OrderSummaryItem.right {
    text-align: right;
}

.OrderSummaryItem.total {
    border-top:1px solid rgba(204, 204, 204, 0.3);
    border-bottom:1px solid rgba(204, 204, 204, 0.3);
    font-weight: 500;
}


@media(min-width: 40rem) {
    .OrderSummary {
        border-radius:  15px;
    }
}