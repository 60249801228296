.SocialHandleList {
    padding: 5px 5px;
    color: var(--primary-text-color);
}

.SocialHandleList:hover {
    background-color: var(--foreground-color);
    border-radius: 50%;
}

.SocialHandle {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}