.FooterTop  {
    padding: 10px;
    background-color: var(--footTop-background-color);
    color: var(--primary-text-color);
    transition: background-color 0.3s ease-in-out;
}
.FooterTop > h2 {
    text-align: center;
}
.ItemsContainer {
    margin: 0;
    padding: 0;
    list-style: none;
}

@media (min-width:40rem) {
    .ItemsContainer {
        width: 80%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
}