
.TermsAndConditions {
    padding: 0 15px;
}



.Effective {
    text-align: center;
    color: red;  
      padding: 0 15px;

}