.Input,
.Textarea,
.Select,
.Label {
  width: 100%;
  display: block;
  padding: 8px;
  font-weight: bold;
}
.InputDate {
  width: 100%;
  display: block;
  padding: 8px 13px;
  font-weight: bold;
  color: var(--primary-text-color);

  height: 40px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: var(--background-color);
  color: var(--primary-text-color);
  transition: background-color 0.3s ease-in-out;
}

.Input,
.Select {
  height: 40px;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: var(--background-color);
  color: var(--primary-text-color);
  transition: background-color 0.3s ease-in-out;
}
.Select {
    border-radius: 0;
}

.Input:focus,
.Textarea:focus,
.Select:focus {
  outline: none;
  /* background-color: rgba(138, 43, 226, 0.3);
  border-color: blueviolet; */
  background-color:var(--inputbackground-color);
    border-color:var(--inputborder-color);
}
.InputDate:focus {
  outline: none;
  background-color:var(--inputbackground-color);
  border-color:var(--inputborder-color);
}

.Textarea {
  height: 120px;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: var(--background-color);
  color: var(--primary-text-color);
  transition: background-color 0.3s ease-in-out;

}

.CheckContainer {
    display: flex;
    align-items: center;
}
input[type="checkbox"] {
    height: 1.1rem;
    width: 1.1rem;
    border: 1px solid #ccc;
    margin-right: 8px;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type="checkbox"]:checked {
    border-color: white;
    background-color:  #00002e;
}
.Relative {
    position: relative;
}
.Svg   {
    position: absolute;
    right: 3%;
    bottom: 3%;

  }

  .Icon {
    vertical-align: middle;
  }

  .CustomeLink {
    color: black;
  }
  .CustomeLink :hover {
    color: black;
  }
  /* .Input,
  .Textarea, */

  /* .Input[class="Invalid"],
  .Textarea[class="Invalid"] {
  background-color: rgb(250, 166, 166);
  border-color: red;
} */
.Invalid {
background-color: rgb(250, 166, 166);
border-color: red;
} 

.error__message {
  margin: 0;
  padding: 0;
  color: red;
}

@media (max-width: 339px) {
  .error__message {
    font-size: 10px;
  }
}
