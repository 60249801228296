.Sort {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Sort > span {
    padding-bottom: 2px;
    font-weight: 500;
    vertical-align: middle;
}
.SortWrapper {
    margin-left: 5px;
    display: flex;
    justify-content:center ;
    align-items: center;
    border: 1px solid  #ccc;
    border-radius: 4px;
    padding: 1px 2px 1px 5px;
    background-color: white;
}
.Select {
    border: none;
    outline: none;
    color: black;
    font-weight: bold;
    background-color: transparent;
}

.SortBtn {
    border:1px solid #ccc;
    padding: 3px 5px;
    background-color:rgba(204, 204, 204, 0.4) ;
    border-radius: 2px;
    color: black;
}

.SortBtn:hover {
    border: 1px solid  #ccc;
    /* padding: 5px 8px; */
    background-color:rgba(204, 204, 204, 0.4) ;
    border-radius: 2px;
    color: red;
}

@media(min-width: 40rem) {
    .Select {
        width: 200px;
    }
}