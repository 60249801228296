.ProductList {
  width: 98%;
  max-width: 392px;
  margin: auto;
  margin-bottom: 30px;
  /* padding: 5px 10px; */
  /* background-color: var(--foreground-color); */
  border-radius: 10px;
  /* border: 1px solid #ccc; */
  /* box-shadow: 2px 4px 6px 2px var(--home-shadow-wrapper-color) ; */
  text-align: center;
}

.ProductListImg.ImageTwo {
  display: none;
}

.ProductList:hover {
  border: 1px solid #ccc;
  box-shadow: 2px 4px 6px 2px var(--home-shadow-wrapper-color);
}

.ProductList > a {
  text-decoration: none;
  /* color: blueviolet; */
  color: var(--primary-text-color);
}

.ProductListImgWrapper {
  width: 100%;
}
.ProductListImgContainer {
  position: relative;
  border-radius: 10px 10px 0 0;
  height: 350px;
  width: 100%;
  overflow: hidden;
}
.ProductListImgContainer > .ProductListImg {
  border-radius: 10px 10px 0 0;
  height: 100%;
  width: 100%;
}
.Wishlist {
  text-decoration: none;
  color: red;
  position: absolute;
  top: 2%;
  right: 2%;
  padding: 0;
  margin: 0;
}

.Wishlist > * {
  padding: 0;
  margin: 0;
}

.ProductListContents {
  transform: translateX(-200%);
  position: absolute;
  left: 2%;
  top: 5%;
  transition: transform 0.5s ease-in-out;
}
.ProductListContents > * {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.ProductListItems {
  transform: translateX(200%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 15%;
  right: 2%;
  transition: transform 0.5s ease-in-out;
}
.ProductListItems > a {
  display: inline-block;
  text-decoration: none;
  color: red;
  /* color: var(--primary-text-color); */
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

/* IMAGE HOVER START-================================- */
.ProductList:hover .ProductListItems {
  transform: translateX(0);
}
.ProductList:hover .ProductListContents {
  transform: translateX(0);
}
.ProductList:hover .ProductListImg.ImageTwo {
  display: block;
}

.ProductList:hover .Default {
  display: none;
}
/* IMAGE HOVER END-================================- */

.ProductListInfo {
  padding: 10px;
  padding-bottom: 20px;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  background-color: var(--foreground-color); 
   transition: background-color 0.3s ease-in-out;
}
.ProductListInfo > h5 {
  margin: 10px;
}
.ProductListInfo > h4 {
  color:var(--primary-text-color);
  font-weight: bolder;
  margin: 15px 0;
}
.ProductListInfo > a {
  display: inline-block;
  color: var(--primary-text-color);
  text-decoration: none;
  text-align: center;
  padding: 10px 20px;
  border: 2px solid gray;
  border-radius: 5px;
}

@media (min-width: 40rem) {
  .ProductList {
    width: 49%;
    margin: 0;
    max-width: none;
    margin-bottom: 30px;
  }
}

@media (min-width: 838px) {
    .ProductList {
      width: 30%;
      margin: 0;
      max-width: none;
      margin-bottom: 30px;
    }
  }
