.ContentItem {
  margin-top: 1rem;
  width: 100%;
  /* padding: 0.3rem 0.8rem; */
}

.ContentItem:hover {
  box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.2);
  background-color: rgb(241, 241, 241);
  color: var(--text-hover);
}
.ContentItem > button {
  border: none;
  display: inline-block;
  width: 100%;
  text-align: left;
  background-color: transparent;
  color: var(--primary-text-color);
  font: inherit;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.ContentItem > button:focus {
  outline: none;
}

.ContentItem > button:hover {
  color: blueviolet;
}

/*  ****************************************************************  */

.sidebar-item {
  padding: 0.75em 1em;
  display: block;
  transition: background-color 0.2s;
  border-radius: 5px;
}

.sidebar-item:hover {
  background-color: var(--dynamic-sidebarbackground-color);
}

.sidebar-title {
  display: flex;
  font-size: 1.2em;
  justify-content: space-between;
}

.sidebar-title span .i {
  display: inline-block;
  width: 1.5em;
}

/* Toggle icon */
.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}

.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(-180deg);
}

.sidebar-content {
  padding-top: 0.25em;
  padding-left: 0.8rem;
  height: 0;
  overflow: hidden;
}

.sidebar-item.open > .sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  color: var(--primary-text-color);
  text-decoration: none;
}
.sidebar-item.plain:hover {
  text-decoration: underline;
}

.sidebar-item.plain .i {
  display: inline-block;
  width: 1.7em;
}



.sidebar-item.btn {
  color: var(--primary-text-color);
  background-color: inherit;
  width: 100%;
  text-align: left;
  border: none;
}
.sidebar-item.btn:focus {
  outline: none;
}

.sidebar-item.btn:hover {
  background-color: var(--dynamic-sidebarbackground-color);
}
.sidebar-item.btn .i {
  display: inline-block;
  vertical-align: middle;
} 


.sidebar-item.btn .i.btnActive {
  color: red;
  vertical-align: middle;
  margin: 0 3px;
}
