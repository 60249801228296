.ContentsNav {
    width: 100%;
}

/* .Contents {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
} */
