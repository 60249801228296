.Layout {
    min-height: calc(100vh - 3.29rem - 842.31px);
    margin-top: 3.29rem;
    /* background-color: var(--background-color);
    transition: background-color 0.3s ease-in-out; */
}

div.LayoutWrapper {
    color: var(--primary-text-color);
    background-color: var(--background-color);
    transition: background-color 0.3s ease-in-out;
}