.CatItem {
    display: block;
    margin: 0.8rem;
    text-decoration: none;
    color: var(--primary-text-color);
    transition: translate 400ms ease-in-out;
}

.CatImgWrapper {
    width: 330px;
    height: 250px;
    min-width: 300px;
    border-radius: 15px;
}

.CatImgWrapper > .CatImg {
    width: 100%;
    height: 100%;
    border-radius: 15px;

}

.CatItem > h5 {
    text-align: left;
}