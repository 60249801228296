.LayoutControl {
  display: none;
  justify-content: center;
  align-items: center;
}


.Control {
    border:1px solid rgba(204, 204, 204, 0.4);
    padding: 3px 5px;
    border-radius: 5px;
    margin-left: 10px;
}

.Icon {
    transform: rotate(90deg);
    vertical-align: middle;
}
.IconOne {
    vertical-align: middle;
}



.Three {
    display: none;
}

.Four {
    display: none;
}
@media(min-width: 40rem) {
    .LayoutControl {
        display: flex;
    }
}

@media (min-width: 850px) {
    .Three {
        display: block;
    }

}

@media (min-width: 1100px) {
    .Four {
        display: block;
    }
}