.ContactInput {
  width: 100%;
  border-radius: 5px;
  height: 40px;
}

.inputClass {
  width: 100% !important;
  height: 100% !important;
  background-color: var(--background-color) !important;
  color: var(--primary-text-color);
}

.inputClass:focus {
  outline: none;
  background-color:var(--inputbackground-color) !important;
}
.Invalid {
  background-color: rgb(250, 166, 166) !important;
}


/* background-color:var(--inputbackground-color);
    border-color:var(--inputborder-color); */