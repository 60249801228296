.NavItem {
    margin: 0 0.6rem;
}
.NavItem  a {
    text-decoration: none;
    color: var(--primary-text-color);
}

a.active {
    border-bottom: 1px solid   rgb(10, 10, 119);
}

.NavItem  a:hover {
    color: var(--text-hover);
}
/* .FilterModalFooterClass {
    padding: 0 !important;
} */

