.WhyChooseUs {
  text-align: center;
  margin-bottom: 25px;
}

.Item {
  background-color: var(--foreground-color);
  margin: 0 auto 20px auto;
  padding: 10px 5px;
  text-align: center;
  border-radius: 12px;
  width: 90%;
  box-shadow: 2px 4px 6px 2px var( --shadow-wrapper-color);
  /* box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.6); */
}
.Item > header {
  font-size: 18px;
  font-weight: bold;
}

.DiffImgWrapper {
    width: 100%;
}

.DiffImgWrapper > img {
    width: 100%;
}


@media (min-width: 40rem) {
  .WhyChooseUs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  .Item {
    background-color: var(--foreground-color);
    padding: 10px 5px;
    text-align: center;
    border-radius: 12px;
    width: 30%;
  }

}
