.Faq {
  margin-bottom:35px;
}

.FaqHeader {
  text-align: center;
  padding: 10px;
}
.FaqHeader > * {
  margin: 0;
}
.FaqHeader > h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.FaqHeader > h4 {
  color: gray;
  margin-bottom: 8px;
}
.ContactBtn {
  padding: 0;
}

@media (min-width: 40rem) {
  .FaqContainer {
    max-width: 1000px;
    margin: auto;
    display: grid;
    grid-template-columns:  repeat(2, 50%);
    grid-template-rows:  200px auto auto;
    /* grid-template-areas:
    "head img"
    "form img"
    ". 1mg"; */
    row-gap: 15px;
    column-gap: 30px;
   
  }
  .FaqHeader {
    /* grid-area: head; */
    grid-column: 1/2;
    grid-row:1/ 2 ;
  }

  .FaqDescription {
    /* grid-area: img;    */
     padding: 25px;
     grid-column: 2/2;
     grid-row:1/4;
  }
  .FaqFormGrid {
    grid-column: 1/2;
    grid-row:2/3 ;

  }
}
