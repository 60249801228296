.ImgWrapper {
    text-align: center;
}

.ImgWrapper > small {
    color: red;
}

.image-upload__preview {
    width: 200px;
    height: 160px;
    margin: auto;
    border-radius: 8px;
}

.image-upload__preview > img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.ImgButton {
    margin: auto;
    margin-top: 10px;
}