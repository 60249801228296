.BoldrixIntro {
  padding: 10px;
}

.ImgWrapper {
  width: 107px;
  height: 40px;
  margin-bottom: 7px;
}

.ImgWrapper > .Img {
  width: 100%;
  height: 100%;
}

.BoldrixIntro p {
  font-weight: 500;
  margin: 0;
}
