.NavItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.NavIcon {
    vertical-align: middle;
}