.SearchListItems {
    margin: 0;
    padding: 0;
    list-style: none;
}





.ListItem {
    border-bottom:1px solid  rgba(204, 204, 204, 0.3);
}
.ListItem > a {
    display: block;
    width: 100%;
    padding: 5px 0;
    text-decoration: none;
    color: white;
}

.ItemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ImageContainer {
    height: 45px;
    width: 45px;
    margin-right: 15px;
}
.ImageContainer > img {
    height: 100%;
    width: 100%;
}

.ListItem:hover {
    scale: 1.01;
}