.ResetPasswordContainer {
  padding-top: 30px;
  margin-bottom: 35px;
}

.ResetPassword {
  padding: 20px;
  border-radius: 20px;
  background-color: var(--foreground-color);
  width: 95%;
  margin: auto;
  max-width: 640px;
}

.form-title__ {
  text-align: center;
  margin: 0;
  color: gray;
}

#form__wrapper {
  display: grid;
  grid-auto-rows: auto;
  row-gap: 15px;
}

.button-Grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
