.Contacts {
  width: 95%;
  margin: 30px auto;
  background-color: var(--foreground-color);
  padding: 10px 10px;
  border-radius: 15px;
}

.Form > button {
  all: unset;
  text-align: center;
  width: 100px;
  height: 25px;
  padding: 5px 3px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: black;
  color: white;
}


.Form {
  padding-bottom: 25px;
}
.Form > button:focus {
  outline: none;
}
.Form > button:hover {
  background-color: var(--specialcontent-background-color);
  color: black;
}
.Form > button:disabled {
  border-color: gray;
  background: #ccc;
  color: gray;
  cursor: not-allowed;
}

.Form > button:disabled:hover {
  border-color: gray;
  background: #ccc;
  color: gray;
  cursor: not-allowed;
}
.InTouch > .Items {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

 .Item > span   {
  margin-right: 0.5rem;
  vertical-align: middle;
}

.Item {
  margin-bottom: 17px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (min-width: 40rem) {
  .Contacts {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding-bottom: 20px;
  }
  .InTouch {
    width: 48%;
  }

  .Form {
    padding-bottom: 0;
    width: 48%;
  }
}
