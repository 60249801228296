/* .HomeImage {
    background-image: url("../../../../images/Copy-of-Copy-of-Ask-a-sommelier-copy-e1626189095829.jpg")  ;
    width: 100%;
    height: 25vh;
    background-size: cover;
    background-position: center;
}

@media (min-width: 365px) {
    .HomeImage  {
        background-position: 0% 10%;
    } 
}

@media (min-width: 514px) { 
    .HomeImage  {
        height: 33vh;
    }
}

@media (min-width: 800px) {
    .HomeImage  {
        height: 50vh;
    }
}

@media (min-width: 927px) {
    .HomeImage  { 
        height: 55vh;
    }
} */


.img-slider-btn {
    all: unset;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 1rem;
    cursor: pointer; 
    transition: background-color 100ms ease-in-out;
  }
  
 

  .img-slider-btn:hover,
  .img-slider-btn:focus-visible {
    background-color: rgb(0, 0, 0, 0.2);
  }

  .img-slider-btn:hover > * ,
   .img-slider-btn:hover > * {
    animation: squish 200ms ease-in-out;
  }


  .img-slider-btn > * {
    /* stroke: black;
    fill: white; */
    width: 2rem;
    height: 2rem;
  }

  @keyframes squish {
    50% {
      scale: 1.4 0.6;
    }
  }

  .img-slider-dot-btn {
    all: unset;
    display: block;
    cursor: pointer;
    width: 0.7rem;
    height: 0.7rem;
    transition: scale 100ms ease-in-out;
  }
  
  .img-slider-dot-btn:hover,
  .img-slider-dot-btn:focus-visible {
    scale: 1.2;
  }
  
  .img-slider-dot-btn > * {
    /* stroke: black;
    fill: var(--foreground-color); */
color: white;
    height: 100%;
    width: 100%;
  }
  
  .img-slider-dot-btn:focus-visible,
  .img-slider-btn:focus-visible {
    outline: auto;
  }

  @media(min-width: 40rem) {
    .img-slider-dot-btn  {
        width: 1rem;
        height: 1rem;
    }
  }