.ContentsNav {
    width: 100%;
}

.Contents {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: start;
}




/*  **************************************************  */

.DemoSidebar {
    /* background-color: rgb(22, 22, 22, 1); */
    color: var(--primary-text-color);
}