.CartHead {
  padding: 1px;
}
.CartHead > h2 {
  text-align: center;
  font-size: 20px;
  color: gray;
}

.Icon {
  vertical-align: middle;
  margin-right: 8px;
}
.CartHeader {
  padding: 10px 15px;
  background-color: var(--foreground-color);
  max-width: 40rem;
  margin: auto;
  margin-bottom: 30px;
}
.Subtotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Subtotal > * {
  margin: 0;
}
.CartHeader > h5 {
  margin: 10px 0;
}
.CartHeader > small {
  color: gray;
}

.CartItem {
  margin-bottom: 20px;
}
.Checkout {
  display: flex;
  padding: 8px 12px;
  /* justify-content: flex-start; */
  align-items: center;
  max-width: 40rem;
  margin: auto;
  margin-bottom: 30px;
  background-color: var(--foreground-color);
}
.BtnCall {
    border: 2px solid black;
    padding: 12.5px;
    color: var(--primary-text-color);
    width: 50px;
    border-radius: 4px;
    margin-right: 10px;
}

.BtnCheckout {
    width: calc(100% - 51px);
    border-radius: 5px;
    padding: 14.5px;
    border: none;
    /* background-color: var( --outdoor-background-color);    */
    /* background-color:darkgray;    */

}

.Refunds {
    padding: 10px 15px;
    border-radius: 5px;
    width: 95%;
    background-color: var(--foreground-color);
    max-width: 40rem;
    margin: auto;
    margin-bottom: 40px;
}
.Refunds > h4 {
    margin: 0;
    margin-bottom: 5px;
}
.Refunds > small {
    color: gray;
}

@media (min-width: 40rem) {
  .CartItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: start;
  }
}
