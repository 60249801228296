.AddToCart {
  width: 95%;
  margin: auto;
  max-width: 780px;
  border-radius: 10px;
  background-color: var(--foreground-color);
}
.header {
  background-color: var( --outdoor-background-color);
  padding: 20px;
  border-radius: 10px 10px 0 0;
  /* margin-bottom: 25px; */
}
.header > h2 {
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: white;
}
.header > h2 > span {
  color: rgb(240, 30, 30);
}
.AddToCartInfo {
  padding: 15px 15px 5px 15px;
}
.price span.stroke {
text-decoration: line-through;
font-weight: 300;
margin-left: 10px;
}
.price > h2 {
  margin-bottom: 5px;
}
.price > small,
.toggle > small {
  margin-bottom: 10px;
  font-weight: 300;
  display: block;
}
.toggle > small {
  margin-bottom: 5px;
}

.toggleButtons {
  width: 100%;
  height: 43px;
  /* margin: auto; */
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #ccc;
  font-weight: bold;
}

.toggleButton {
  all: unset;
  display: block;
  padding: 0 10px;
  height: 100%;
  font-size: 25px;
  outline: none;
  cursor: pointer;
}
/* .toggleButton:focus {
    outline: none;
} */

.toggleButton:disabled {
  border-color: gray;
  background: #ccc;
  color: gray;
  cursor: not-allowed;
  border-radius: 20px;
}

.toggleButton:disabled:hover {
  border-color: gray;
  background: #ccc;
  color: gray;
  cursor: not-allowed;
  border-radius: 20px;
}

.AddToCartButton {
  display: block;
  width: 100%;
  padding: 10px 10px;
  height: 40px;
  margin-bottom: 25px;
  border-radius: 20px;
  border: 1px solid #ccc;
  text-align: center;
  font-weight: bold;
  background-color: black;
  color: white;
  outline: none;
}

.AddToCartButton:hover {
  background-color: var(--specialcontent-background-color);
  color: black;
}

.AddToCartButton:disabled {
  border-color: gray;
  background: #ccc;
  color: gray;
  cursor: not-allowed;
}

.AddToCartButton:disabled:hover {
  border-color: gray;
  background: #ccc;
  color: gray;
  cursor: not-allowed;
}
.AddToCartFooter {
    padding: 15px;
}

.AddToCartFooter h5 {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 300;
}
.AddToCartFooter h5 > .Icons {
    margin-right: 0.5rem;
}

@media (min-width: 40rem) {
  .AddToCartInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* .AddToCartFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

  .price {
    width: 40%;
  }
  .toggle {
    width: 60%;
  }
}
