.Input,
.Select > select,
.Textarea {
    display: block;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding: 8px 8px;
    font-weight: bold;
    color: var(--primary-text-color);
    background-color: var(--background-color);
    border: 1px solid #ccc;
    transition: background-color 0.3s ease-in-out;
}

.Input,
.Select,
.Textarea {
    margin-bottom: 17px;
}


.Select {
    width: 100%;
} 
.Select > label {
    font-weight: bold;
}


.Textarea {
    height: 120px;
    font-size: 16px;
}


.Input:focus,
.Select > select:focus,
.Textarea:focus {
outline: none;
/* background-color: rgba(138, 43, 226, 0.3);
    border-color: blueviolet; */
    background-color:var(--inputbackground-color);
    border-color:var(--inputborder-color);
}

