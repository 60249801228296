.CoreListItem {
    padding: 8px;
    width: 45%;
    text-align: start;
}

.CoreListItem a {
    text-decoration: none;
    color: var(--primary-text-color);
}

.CoreList {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}