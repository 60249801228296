.ImageOne {
  width: 100%;
  height: 70vh;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: var(--primary-text-color);
  transition: translate 400ms ease-in-out;
}

.VidIntro {
  height: 100%;
  width: 100%;
  object-fit: cover;
}



/* .ImageOne {
    background: linear-gradient(to top, rgba( 0, 0, 46, 0.6) 10%, transparent), url("../../../../../images/Copy-of-Copy-of-Ask-a-sommelier-copy-e1626189095829.jpg");
  background-image: url("../../../../../images/Copy-of-Copy-of-Ask-a-sommelier-copy-e1626189095829.jpg"); */
  /* width: 100%;
  height: 25vh;
  background-size: cover;
  background-position: center;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: red;
  transition: translate 400ms ease-in-out;
} */

.ImageOneChild {
  position: absolute;
  max-width: 367px;
  max-height: 123px;
  top: 30%;
  left: 3%;
  bottom: 12%;
  width: 47%;
  color: white;
  padding: 5px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
}

.ImageOneChild > h4 {
  font-weight: bold;
  margin: 5px 0;
}
.ImageOneChild > small {
  padding: 2px;
}
.ImageOneChild > .hide {
  display: none;
}

.ImageOneChild > .head {
  display: none;
}

.ImageOneChild > hr {
  width: 50%;
  height: 4px;
  color: white;
  background-color: white;
}


.ImageOneChild > button {
  display: block;
  margin-top: 3px;
  padding: 3px 5px;
  color: white;
  background-color: rgb(10, 10, 119);
  border: none;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.ImageOneChild > button:focus {
  outline: none;
}

.Open {
  animation: open-motion 3000ms ease-in-out forwards;
}

@keyframes open-motion {
  0% {
    transform: translateY(30%);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

/* @media (min-width: 365px) {
  .ImageOne {
    background-position: 0% 10%;
  }
} */

@media (min-width: 492px) {
  .ImageOneChild > .head {
    display: block;
  }
}
/* @media (min-width: 514px) {
  .ImageOne {
    height: 33vh;
  }
} */

@media (min-width: 40rem) {
  .ImageOneChild {
    padding-top: 20px;
    padding-bottom: 2px;
  }
  .ImageOneChild > .hide {
    display: block;
  }

  .Open {
    animation: open-motion 3000ms ease-in-out forwards;
}

@keyframes open-motion {
    0% {
        transform: translateY(70%);
    }
    50% {
        transform: translateY(-10%);
    }
    100% {
        transform: translateY(0);
    }
}
}

@media (min-width: 800px) {
  .ImageOne {
    height: 50vh;
  }
}

@media (min-width: 927px) {
  .ImageOne {
    height: 60vh;
  }
}
