.ImageThree {
    background: linear-gradient(to top, rgba( 0, 0, 0, 1) 10%, transparent), url("../../../../../images/FB_IMG_1724323238602.jpg");
    /* background-image: url("../../../../../images/Wine-Heart-Health.jpg")  ; */
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: black;
    transition: translate 400ms ease-in-out;
}



.ImageThreeChild {
    position: absolute;
    max-width: 367px;
    max-height: 123px;
    top: 30%;
    left: 3%;
    bottom: 12%;
    width: 47%;
    color: white;
    padding: 5px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .ImageThreeChild > h4 {
    font-weight: bold;
    margin: 5px 0;
  }
  .ImageThreeChild > small {
    padding: 2px;
    font-size: 12px;
  }

  .ImageThreeChild > .hide {
    display: none;
  }
  
  .ImageThreeChild > hr {
    width: 50%;
    height: 4px;
    color: white;
    background-color: white;
  }
  
  .ImageThreeChild > button {
    display: block;
    margin-top: 3px;
    padding: 3px 5px;
    color: white;
    background-color: rgb(10, 10, 119);
    border: none;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .ImageThreeChild > button:focus {
    outline: none;
  }
  
  .Open {
    animation: open-motion 3000ms ease-in-out forwards;
  }
  
  @keyframes open-motion {
    0% {
      transform: translateY(30%);
    }
    50% {
      transform: translateY(-10%);
    }
    100% {
      transform: translateY(0);
    }
  }




@media (min-width: 365px) {
    .ImageThree  {
        background-position: 0% 10%;
    } 
}

@media (min-width: 373px) {
  .ImageThreeChild > small {
    font-size: 13px;
  }
}

@media (min-width: 514px) { 
    /* .ImageThree  {
        height: 33vh;
    } */
}

@media (min-width: 40rem) {
    .ImageThreeChild {
      padding-top: 30px;
      padding-bottom: 2px;
    }
    .ImageThreeChild > small {
      font-size: 16px;
    }
    .ImageThreeChild > .hide {
      display: block;
    }
  
    .Open {
      animation: open-motion 3000ms ease-in-out forwards;
  }
  
  
  @keyframes open-motion {
      0% {
          transform: translateY(70%);
      }
      50% {
          transform: translateY(-10%);
      }
      100% {
          transform: translateY(0);
      }
  }
  }

@media (min-width: 800px) {
    .ImageThree  {
        height: 50vh;
    }
}

@media (min-width: 927px) {
    .ImageThree  { 
        height: 60vh;
    }
}