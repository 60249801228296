.SideDrawer {
  position: fixed;
  left: 0;
  top: 0;
  width: 280px;
  max-width: 70%;
  padding: 0px 0 10px 0;
  height: 100%;
  overflow-y: auto;
  /* background-color: rgb(245, 243, 243); */
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  z-index: 102;
  transition: background-color 0.3s ease-in-out;
  outline: none;
}


.SideDrawer::-webkit-scrollbar {
  width: 1vw;
}

.SideDrawer::-webkit-scrollbar-thumb {
  background-color: var(--background-color);
}
/* aside::-webkit-scrollbar-thumb {
    background-color: var(--background-color);
  } */

.SideDrawer::-webkit-scrollbar-track {
  background-color: var(--foreground-color);
}

/* .OpenSide {
    transform: translateX(0);
}
.CloseSide {
    transform: translateX(-100%);
} */

.OpenSide {
  animation: openSlide 0.3s ease-out forwards;
}
.CloseSide {
  animation: closeSlide 0.3s ease-out forwards;
}

.SideDrawerHeader {
  padding-top: 8px;
  padding-left: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .SideDrawerHeader > h1 {
  margin: 0;
  font-size: 25px;
  color: var(--brand-color);
} */

.SideDrawerHeader > a {
  display: inline-block;
  height: 35px;
  width: 95px;
    text-decoration: none;
    vertical-align: middle;
    /* font-weight: bold;
    font-size: 25px;
    color: var(--brand-color); */
}
.SideDrawerHeader > a > .BoldrixImg {
  width: 100%;
  height: 100%;
}

.SideDrawerHeader > span {
  color: var(--primary-text-color);
  vertical-align: middle;
}
.SideNavItems,
.SideContents {
  margin-top: 30px;
}
.SideContents {
  border-top: 1px solid rgba(204, 204, 204, 0.4);
}

@keyframes openSlide {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  50% {
    opacity: 0.8;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes closeSlide {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 0.8;
    transform: translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}
