.Profile {
    margin-bottom: 35px;
}

.ProfileDescription {
    padding: 15px;
    max-width: 40rem;
    margin: auto;
}
.ProfileImageWrapper {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    margin: auto;
}
.ProfileImageWrapper > img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.ProfileUserName,
.ProfileBio {
    text-align: center;
}
.ResetPass {
display: block;
text-align: center;
color: red;
padding: 0;
}
.ResetPass > span {
    vertical-align: middle;
    margin-right: 8px;
} 

.ProfileNavigations {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.ProfileCart > span {
    vertical-align: middle;
    margin-right: 5px;
}

.ProfileOrders > span {
    vertical-align: middle;
    margin-left: 5px; 
}