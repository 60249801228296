.ErrorModal {
    background-color: white;
}

.ErrorModal > .ErrorHeader {
    background-color: red;
}
/* .ErrorHeader h3 {
    color: var(--foreground-color)
} */

.ErrorHeader > h3 { 
    color: var(--primary-text-color);
    font-size: 21px;
}

.ErrorContent  p {
    color: red;
    margin: 0;
}

.ErrorFooter {
    padding: 15px;
}