.FooterTopItem {
    padding: 10px;
    margin: 10px;
}
.ItemContainer {
    text-decoration: none;
    color: var(--primary-text-color);
    display: flex;
    align-items: center;
}
.ItemIcons {
    margin-right: 7px;
}

@media (min-width:40rem) {
    .FooterTopItem {
        width: 45%;
    }
    
}