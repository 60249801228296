.ProductList {
  padding: 10px;
  /* padding-bottom: 20px; */
  position: relative;
  overflow: hidden;
  width: 98%;
  /* max-width: 392px; */
  margin: auto;
  margin-bottom: 30px;
  /* padding: 5px 10px; */
  background-color: var(--foreground-color);
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;
  /* border: 1px solid #ccc; */
  /* box-shadow: 2px 4px 6px 2px var(--home-shadow-wrapper-color) ; */
}

.ProductListImg.ImageTwo {
  display: none;
}

.ProductList > a {
  text-decoration: none;
  /* color: blueviolet; */
  color: var(--primary-text-color);
}

.StoreItemLink {
  display: flex;
  justify-content: center;
  align-items: start;
}

.ProductListImgWrapper {
  width: 20%;
}
.ProductListImgContainer {
  height: 100px;
  width: 100%;
  max-width: 140px;
  margin: auto;
}

.ProductListImgContainer > .ProductListImg {
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.Wishlist {
  text-decoration: none;
  color: red;
  position: absolute;
  top: 2%;
  right: 2%;
  padding: 0;
  margin: 0;
}

.Wishlist > * {
  padding: 0;
  margin: 0;
}

.ProductListItems {
  transform: translateX(210%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 26px;
  right: 2%;
  transition: transform 0.3s ease-in-out;
}
.ProductListItems > a {
  display: inline-block;
  text-decoration: none;
  color: red;
  /* color: var(--primary-text-color); */
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

/* IMAGE HOVER START-================================- */
.ProductList:hover .ProductListItems {
  transform: translateX(0);
}
.ProductList:hover .ProductListContents {
  transform: translateX(0);
}
.ProductList:hover .ProductListImg.ImageTwo {
  display: block;
}

.ProductList:hover .Default {
  display: none;
}
/* IMAGE HOVER END-================================- */

.ProductListInfo {
  width: 80%;
  padding-left: 8px;
  text-align: left;
  /* background-color: var(--foreground-color);
  transition: background-color 0.3s ease-in-out; */
}
.ProductListInfo > * {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}
.ProductListInfo > h5:first-child {
color: red;
}
.ProductListInfo > h5:nth-last-of-type(2) {
  color: gray;
}

@media (min-width: 40rem) {

  .Two {
    width: 48%;
    /* min-height: 330px;
    max-height: 381px; */
    height: 381px;
    margin-top: 0;
  }

  .Two > .StoreItemLink {
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    text-align: start;
  }
   .Two  .ProductListImgWrapper {
    width: 100%;
    margin-bottom: 20px;
  }
   .Two  .ProductListImgContainer {
    height: 150px;
  }
.Two .ProductListInfo  {
  width: 100%;
}

}
@media(min-width: 800px) {
  .Two {
    /* min-height: 330px;
    max-height: 381px; */
    height: 340px;
    /* margin-top: 0; */
  }
}
@media (min-width: 850px) {
  
  .Three {
    width: 28%;
    height: 381px;
    margin-top: 0;
  }

  .Three > .StoreItemLink {
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    text-align: start;
  }
   .Three  .ProductListImgWrapper {
    width: 100%;
    margin-bottom: 20px;
  }
   .Three  .ProductListImgContainer {
    height: 150px;
  }
.Three .ProductListInfo  {
  width: 100%;
}

}

@media (min-width: 1100px) {
  .Four {
    width: 24%;
    height: 381px;
    margin-top: 0;
  }

  .Four > .StoreItemLink {
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    text-align: start;
  }
   .Four  .ProductListImgWrapper {
    width: 100%;
    margin-bottom: 20px;
  }
   .Four  .ProductListImgContainer {
    height: 150px;
  }
.Four .ProductListInfo  {
  width: 100%;
}
}

/* 
@media (min-width: 800px) {
}

@media (min-width: 838px) {
  .ProductList {
    width: 30%;
    margin: 0;
    max-width: none;
    margin-bottom: 30px;
  }
} */
