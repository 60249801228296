.CartCard {
    width: 95%;
    margin: auto;
    margin-bottom: 13px;
    max-width: 459px;
    padding: 13px 8px;
    border-radius: 15px;
    background-color: var(--foreground-color);
}
.CardDescriptionContainer {
    display: flex;
    align-items: start;
}

.CartImgContainer {
    height: 120px;
    width: 35%;
}
.CartImgContainer > img {
    height: 100%;
    width: 100%;
}

.description {
    width: 65%;
    padding-left: 8px;
    margin-bottom: 10px;
}
.description > p {
    margin: 0;
    font-size: 14px;
    margin-bottom: 5px;
}
.description > h4 {
    margin: 5px 0;
}

.description > small {
color: gray;
}
.description > small span {
    color:var(--primary-text-color);
}


.toggle {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.Icon {
    vertical-align: middle;
}

.toggle {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.ButtonRemove {
all: unset;
display: block;
font-weight: 500;
font-size: 16px;
color: red;
}
.ButtonRemove:focus {
    outline: none;
}
.ButtonRemove > span {
    margin-right: 10px;
}

.toggleButtons {
   width: 170px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
  
  .toggleButton {
    all: unset;
    display: block;
    /* padding: 8px;*/
    height: 32px; 
    text-align: center;
    width: 40px;
    border-radius: 5px;
    background-color: black;
    /* background-color: var( --outdoor-background-color); */
    color:white;
    font-size: 20px;
    outline: none;
    cursor: pointer;
  }
  /* .toggleButton:focus {
      outline: none;
  } */
  
  .toggleButton:disabled {
    border-color: gray;
    background: #ccc;
    color: gray;
    cursor: not-allowed;
  }
  
  .toggleButton:disabled:hover {
    border-color: gray;
    background: #ccc;
    color: gray;
    cursor: not-allowed;
  }



  @media(min-width:420px) {
    .CartImgContainer {
        height: 150px;
    }
  }

  @media(min-width:40rem) {
    .CartCard {
        width: 45%;
        margin: 0;
        margin-bottom: 15px;
    }
  }

  @media(min-width:1000px) {
    .CartCard {
        width: 32%;
    }
  }
  /* max w 459 */