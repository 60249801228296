.FirstHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
  background-color: var(--firstheaderbackground-color);
  transition: background-color 0.3s ease-in-out; 
}

.FirstHeaderBrand > button,
.FirstHeaderBrand > a {
  margin: 0 0.2rem;
  vertical-align: middle;
}

.FirstHeaderBrand > button {
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}
.FirstHeaderBrand > a {
  display: inline-block;
  height: 35px;
  width: 95px;
    text-decoration: none;
    /* font-weight: bold;
    font-size: 25px;
    color: var(--brand-color); */
}
.FirstHeaderBrand > a > .BoldrixImg {
  width: 100%;
  height: 100%;
}
.FirstHeaderBrand .Bar {
  color: var(--primary-text-color);
}

.Search {
  display: none;
  width: 40%;
  max-width: 450px;
}





@media (min-width: 40rem) {
  .Search {
    display: block;
  }
}