.CheckoutHead {
    padding-top: 1px;
}

.CheckoutHead > h1 {
text-align:  center;
font-size: 20px;
margin: 15px 0;
color: gray;
}

.CheckoutHead > h1  span {
    margin-right: 8px;
}

.CheckoutAcknowledged {
    padding: 5px 15px;
    max-width: 519px;
    margin: auto;
    background-color: var(--foreground-color);
}

.terms {
    padding: 0;
}

.CheckoutAcknowledged > h5 {
    padding: 0;
    margin: 10px 0;
}


.CheckoutSummaryHeader {
    padding: 0 15px;
    margin: 20px auto 10px auto;
    max-width: 40rem;
    color: gray;
}

.CheckoutPaymentMethod {
    padding: 0 15px;
    margin: 20px auto 10px auto;
    max-width: 900px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CheckoutPaymentMethodHeader {
    color: gray;
}
.Change {
    font-weight: 500;
    padding: 0;
}

.PaymentMethod {
    padding: 5px 15px;
    background-color: var(--foreground-color);
    margin: auto;
    max-width: 900px;
    display: flex;
    align-items: center;
}

.PaymentMethod > span {
    margin-right: 10px;
}
.PaymentMethodContentWrapper > h4 {
    margin: 0;
    font-size: 14px;
    /* margin-bottom: 5px; */
}

.PaymentMethodContentWrapper > small {
    font-size: 13px;
}






@media(min-width: 519px) {
    .CheckoutAcknowledged {
    border-radius: 5px;
    }
}